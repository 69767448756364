<template>
    <div
        ref="target"
        class="position-relative select"
        :class="getSelectClass">
        <button
            v-if="state.values.length && multiple && !disabled"
            class="select__reset btn btn-sm btn-light"
            @click="reset">
            <vue-feather
                class="d-flex"
                size="20px"
                type="x" />
        </button>

        <div
            v-if="!state.values.length || !multiple"
            class="select__arrow btn btn-sm btn-light">
            <vue-feather
                class="d-flex"
                size="20px"
                type="chevron-down" />
        </div>
        <div class="input-group select__input">
            <div v-if="multiple && searchable && state.values.length" class="input-group-text">
                Выбрано: {{ getValue }}
            </div>
            <input
                v-model="getModel"
                v-bind="$attrs"
                autocomplete="off"
                class="form-control position-relative"
                :class="getInputClass"
                :disabled="getDisabled"
                :placeholder="getPlaceholder"
                :readonly="getReadonly"
                @focus="focus"
                @input="input($event)">
        </div>
        <simple-bar
            v-if="state.isOpen"
            :auto-hide="false"
            class="card border select__options options simple-bar-dark">
            <div
                v-for="(option, optionInd) in getOptions"
                :key="option.id"
                class="options__item"
                :class="getOptionClass(option)"
                @click="select(option)">
                <slot name="option" :option="option">
                    {{ option.name }} ({{ option.entrance_bin }})
                </slot>
            </div>
            <div v-if="!getOptions.length && !state.loader" class="options__empty">
                Ничего не найдено
            </div>
            <div v-if="state.loader" class="options__loader">
                <loader-ui :is-show="state.loader" size="sm" />
            </div>
        </simple-bar>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from "vue";
import { IUnknownObject } from "@/@types/common";
import { onClickOutside } from "@vueuse/core";
import { api } from '@/api';
import axios from "axios";
import t from "@/utils/t";

export interface IOption extends IUnknownObject {
    id: string | number,
    name?: string,
    entrance_bin?: string
}

interface IState {
    values: IOption[],
    query: string,
    options: IOption[],
    isOpen: boolean,
    loader: boolean,
    position: string
}

export default defineComponent({
    name: "SelectUi3",
    props: {
        options: {
            type: Array as PropType<IOption[]>,
            default: () => ([]),
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Выберите значение',
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        values: {
            type: Array as PropType<IOption[]>,
            default: () => ([]),
        },
        filtering: {
            type: Function as PropType<(option: IOption, query: string) => boolean>,
            default: (option: IOption, query: string) => {
                if (!option.name) return false;
                return option.name.toLowerCase().includes(query.toLowerCase()) ||
                       (option.entrance_bin && option.entrance_bin.toLowerCase().includes(query.toLowerCase()));
            },
        },
        asyncOptionsUrl: {
            type: Function as PropType<(query: string) => string>,
            default: (query: string) => '',
        },
        mode: {
            type: String as PropType<'static' | 'async'>,
            default: 'static',
        },
        isInvalid: {
            type: [ Boolean, Number ],
            default: false,
        },
        isValid: {
            type: [ Boolean, Number ],
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        inputClass: {
            type: String,
            default: '',
        },
        chooseSingleOption: {
            type: Boolean,
            default: true,
        },
    },
    emits: {
        onSelected: (option: IOption) => true,
        onUnSelected: (option: IOption) => true,
        onUpdated: (values: IOption | IOption[]) => true,
        onInput: (query: string) => true,
    },
    setup(props, { emit }) {
        let asyncInterval: any = null;
        const CancelToken = axios.CancelToken;
        let cancel = () => {};

        const target = ref<HTMLElement | null>(null);

        const state = reactive<IState>({
            values: props.values,
            query: '',
            options: props.options,
            isOpen: false,
            loader: false,
            position: 'bottom',
        });

        const getModel = computed({
            get() {
                if (!props.searchable || (props.searchable && !props.multiple)) {
                    if (state.values.length) {
                        return state.values.map((option: IOption) => option.name).join(', ');
                    }
                }

                return state.query;
            },
            set(value: string) {
                state.query = value;
            },
        });

        const getInputClass = computed(() => {
            let classes = [props.inputClass];
            if (!props.searchable) classes.push('input-not-searchable');
            if (props.isInvalid) classes.push('is-invalid');
            if (props.isValid) classes.push('is-valid');
            return classes.join(' ');
        });

        const getPlaceholder = computed(() => {
            return t(props.placeholder);
        });

        const getOptions = computed(() => {
            if (state.query.length === 0 || props.mode === 'async') return state.options;
            else return state.options.filter((option: IOption) => props.filtering(option, state.query));
        });

        const getSelectClass = computed(() => ({
            '--active': state.isOpen,
            'is-invalid': props.isInvalid,
            '--top': state.position === 'top',
            '--bottom': state.position === 'bottom',
        }));

        const getOptionClass = (option: IOption) => ({
            '--selected bg-primary bg-opacity-75': state.values.some((o: IOption) => o.id === option.id),
        });

        const getReadonly = computed(() => {
            if (!props.searchable && !props.disabled) return 'readonly';
            return null;
        });

        const getDisabled = computed(() => props.disabled ? 'disabled' : null);

        const getValue = computed(() => {
            if (state.values.length < 2) {
                return state.values.map((option: IOption) => option.name).join(', ');
            } else {
                return `${state.values.length} варианта`;
            }
        });

        // Закрытие выпадающего списка при клике вне компонента
        onClickOutside(target, (event) => {
            state.isOpen = false;
        });

        onMounted(() => {
            if (props.chooseSingleOption && state.options.length === 1) {
                emit('onSelected', state.options[0]);
            }
            window.addEventListener('scroll', scroll);
            scroll();
        });

        onUnmounted(() => {
            window.removeEventListener('scroll', scroll);
        });

        function scroll() {
            if (!target.value) return;
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = target.value.getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top;
            const bottom = bodyRect.height + Math.abs(bodyRect.top);
            const elem = offset + 33 + 300;

            if (elem > bottom) state.position = 'top';
            else state.position = 'bottom';
        }

        function focus() {
            state.isOpen = true;
        }

        function blur() {
            state.isOpen = false;
        }

        function input(e: Event) {
            const target = e.target as HTMLInputElement;
            if (props.mode === 'async') {
                state.options = [];
                state.values = [];
                state.loader = true;
                clearTimeout(asyncInterval);
                cancel();

                asyncInterval = setTimeout(async () => {
                    try {
                        const response = await axios.get(props.asyncOptionsUrl(state.query), {
                            cancelToken: new CancelToken(function executor(c) {
                                cancel = c;
                            }),
                        });
                        state.options = response.data.data;
                    } catch (error) {
                        if (axios.isCancel(error)) {
                            console.log('Request canceled', error.message);
                        } else {
                            console.error('Ошибка загрузки опций:', error);
                        }
                    } finally {
                        state.loader = false;
                    }
                }, 500);
            }
            emit('onInput', state.query);
        }

        function select(option: IOption) {
            if (props.multiple) {
                const index = state.values.findIndex((o: IOption) => o.id === option.id);
                if (index !== -1) {
                    state.values.splice(index, 1);
                    emit('onUnSelected', option);
                } else {
                    state.values.push(option);
                    emit('onSelected', option);
                }
            } else {
                state.values = [option];
                emit('onSelected', option);
                state.isOpen = false;
            }

            emit('onUpdated', props.multiple ? state.values : state.values[0]);
        }

        function reset() {
            state.values = [];
            emit('onUpdated', props.multiple ? state.values : state.values[0]);
        }

        function syncValuesWithOptions() {
            state.values = state.options.filter((option: IOption) => props.values.some(v => v.id === option.id));
        }

        watch(() => [...props.values], (v, o) => {
            syncValuesWithOptions();
        }, { immediate: true });

        watch(() => props.options, (v: IOption[]) => {
            state.options = v;
            syncValuesWithOptions();
        }, { deep: true });

        return {
            state,
            target,
            getModel,
            getOptions,
            getOptionClass,
            getSelectClass,
            getDisabled,
            getInputClass,
            getValue,
            getPlaceholder,
            getReadonly,
            focus,
            blur,
            select,
            input,
            reset,
        };
    },
});
</script>

<style lang="scss" scoped>

.select.--bottom .select__options {
	margin-top: -1px;
	border-top: none !important;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select.--bottom .options {
	top: auto !important;
	bottom: auto !important;
}

.select.--top .select__options {
	margin-bottom: -1px;
	border-bottom: none !important;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select.--top .options {
	bottom: 32px !important;
	top: auto !important;
}

.select {
	&.--active {
		z-index: 5;
	}

	&__input {
		z-index: 2;

		& input[readonly] {
			background: #fff;
			padding-right: 30px;
		}
	}

	&__options {
		position: absolute;
		left: 0;
		right: 0;
		min-width: 100%;
		max-height: 300px;
	}

    &__reset {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        z-index: 3;
        background: #fff;
        border: none;
        width: 31px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &:hover {
            background: var(--bs-gray-200);
        }
    }

    &__arrow {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        z-index: 3;
        background: transparent;
        border: none;
        width: 31px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        display: flex;
        align-content: center;
        align-items: center;
        pointer-events: none;
    }
}

.options {
	padding: 5px 0;
    width: auto;

    &__item {
        appearance: none;
        background-clip: padding-box;
        background-color: #fff;
        color: #495057;
        display: block;
        font-size: .875rem;
        user-select: none;
        font-weight: 400;
        line-height: 1.5;
        padding: calc(.3rem + 1px) calc(.85rem + 1px);
        width: 100%;
        cursor: pointer;

        &:hover {
            background: var(--bs-gray-200);
        }

        &.--selected {
            color: #fff;
            background-color: var(--bs-primary);
        }
    }

    &__empty {
        background-clip: padding-box;
        background: var(--bs-gray-200);
        color: #495057;
        display: block;
        font-size: .875rem;
        user-select: none;
        font-weight: 400;
        line-height: 1.5;
        padding: calc(.3rem + 1px) calc(.85rem + 1px);
        width: 100%;
    }

	& .simplebar-content {
		height: auto;
		padding: 10px 0 5px !important;
	}

	&__loader {
		height: 30px;
	}
}

.input-not-searchable {
	background: #fff;
	cursor: pointer;
}

</style>
