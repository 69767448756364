<template>
    <nav id="sidebar" class="sidebar">
        <simple-bar class="sidebar-content">
            <router-link class="sidebar-brand p-3 m-0 d-flex align-items-center ms-2" to="/">
                <span class="sidebar-brand-text align-middle">
                    <logo-white height="32px" />
                </span>
                <logo-white-min class="sidebar-brand-icon align-middle" height="32px" />
            </router-link>
            <ul class="sidebar-nav">
                <template v-for="(group, groupInd) in menu" :key="'group' + groupInd">
                    <li v-if="group.groupName !== null && group.isShow" class="sidebar-header">
                        {{ $t(group.groupName) }}
                    </li>
                    <template v-for="(item, itemInd) in group.children" :key="'item' + itemInd">
                        <li class="sidebar-item" :class="{ active: isActive(item) }">
                            <div
                                v-if="item.hasOwnProperty('children') && item.isShow"
                                class="sidebar-link no-select"
                                :class="{ collapsed: !item.open || isActive(item) }"
                                data-bs-toggle="collapse"
                                @click="item.open = !item.open">
                                <component :is="item.icon" />
                                <span class="align-middle">{{ $t(item.title) }}</span>
                            </div>
                            <router-link
                                v-if="!item.hasOwnProperty('children') && item.isShow"
                                class="sidebar-link no-select"
                                :class="{ active: isActive(item) }"
                                :to="{ name: item.routeName }">
                                <component :is="item.icon" />
                                <span class="align-middle">{{ $t(item.title) }}</span>
                            </router-link>
                            <ul
                                v-if="item.hasOwnProperty('children') && (item.open || isActive(item))"
                                class="sidebar-dropdown list-unstyled">
                                <template v-for="(sub, subInd) in item.children" :key="'sub' + subInd">
                                    <li
                                        v-if="sub.isShow"
                                        class="sidebar-item"
                                        :class="{ active: isActive(item) }">
                                        <router-link
                                            class="sidebar-link no-select"
                                            :to="{ name: sub.routeName }">
                                            {{ $t(sub.title) }}
                                        </router-link>
                                    </li>
                                </template>
                            </ul>
                        </li>
                    </template>
                </template>
            </ul>
            <!--            <div class="sidebar-cta">-->
            <!--                <div class="sidebar-cta-content">-->
            <!--                    <div class="d-grid">-->
            <!--                        <router-link class="btn btn-outline-primary" :to="{name: 'InstructionsIndex'}">-->
            <!--                            Инструкции-->
            <!--                        </router-link>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
        </simple-bar>
    </nav>
</template>

<script lang="ts">
import { defineComponent, shallowRef, defineAsyncComponent, ref, computed } from "vue";
import { useRoute } from 'vue-router';
import LogoWhiteMin from '@/assets/img/logo-white-min.svg';
import LogoWhite from '@/assets/img/logo-white.svg';
import { userStore } from "@/store/userStore";
import { v } from "@/utils/v";

export default defineComponent({
    name: "SidebarComponent",
    setup() {
        const route = useRoute();
        const { user } = userStore();

        const menu = ref([
            {
                groupName: null,
                isShow: user.isLoggedIn,
                children: [
                    {
                        title: 'titles.home',
                        isShow: user.isLoggedIn,
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/home.svg'))),
                        routeName: 'Index',
                    }, {
                        title: 'titles.activity',
                        isShow: user.isLoggedIn && (user.isManager || user.isAudit),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/activity.svg'))),
                        routeName: 'ActivityIndex',
                    }, {
                        title: 'titles.humans',
                        isShow: user.isLoggedIn && (!user.isOVPOSignatory && !user.isOVPOOperator),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/humans.svg'))),
                        routeName: 'HumanIndex',
                    }, {
                        title: 'titles.users',
                        isShow: user.isLoggedIn && (user.isSuperAdmin || user.isAdmin),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/humans.svg'))),
                        routeName: 'UserIndex',
                    }, {
                        title: 'titles.entrances',
                        isShow: !user.isUser && (!user.isOVPOSignatory && !user.isOVPOOperator),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/entrances.svg'))),
                        routeName: 'EntranceIndex',
                    }, {
                        title: 'titles.turnstiles',
                        isShow: user.isLoggedIn && (!user.isOVPOSignatory && !user.isOVPOOperator),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/turnstile.svg'))),
                        routeName: 'TurnstileIndex',
                    },
                    {
                        title: 'Учебные заведения',
                        isShow: !user.isUser,
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/moderation.svg'))),
                        routeName: 'InstitutionIndex',
                    },
                    {
                        title: 'titles.reports',
                        isShow: user.isLoggedIn && (!user.isOVPOSignatory && !user.isOVPOOperator),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/reports.svg'))),
                        routeName: 'ReportIndex',
                    },
                    // {
                    //     title: 'titles.moderation',
                    //     isShow: user.isLoggedIn && (user.isManager || user.isAudit),
                    //     icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/moderation.svg'))),
                    //     routeName: 'ModerationIndex',
                    // },
                    {
                        title: 'titles.instructions',
                        isShow: (!user.isOVPOSignatory && !user.isOVPOOperator),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/moderation.svg'))),
                        routeName: 'InstructionsIndex',
                    }, {
                        title: 'titles.deletedHumans',
                        isShow: user.isLoggedIn && (user.isManager || user.isAudit),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/humans.svg'))),
                        routeName: 'DeletedHumanIndex',
                    },{
                        title: 'ОВПО',
                        isShow: (user.isLoggedIn && (user.isOVPOSignatory || (user.isManager || user.isAudit))),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/humans.svg'))),
                        routeName: 'OVPOIndex',
                    },{
                        title: 'ОВПО Отчеты',
                        isShow: user.isLoggedIn && (user.isOVPOSignatory || user.isOVPOOperator || (user.isManager || user.isAudit)),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/reports.svg'))),
                        routeName: 'OVPOReportIndex',
                    },{
                        title: 'Формирование реестра',
                        isShow: user.isLoggedIn && (user.isOVPOSignatory || user.isOVPOOperator || (user.isManager || user.isAudit)),
                        icon: shallowRef(defineAsyncComponent(() => import('@/assets/img/svg/reports.svg'))),
                        routeName: 'RegistriesIndex',
                    }
                ],
            },
        ]);

        const isActive = computed((() => {
            return (item: any) => {
                if (item.hasOwnProperty('routeName') && route.name == item.routeName) return true;
                if (item.hasOwnProperty('children') && item.children.map((i: any) => i.routeName).includes(route.name)) return true;
                if (item.hasOwnProperty('subRoutes') && item.subRoutes.includes(route.name)) return true;

                return false;
            };
        }));

        return { menu, route, isActive };
    },
    components: {
        LogoWhite, LogoWhiteMin,
    },
});
</script>

<style scoped lang="scss">

</style>
