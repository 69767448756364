import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-235cb155"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "select__arrow btn btn-sm btn-light"
}
const _hoisted_2 = { class: "input-group select__input" }
const _hoisted_3 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_4 = ["disabled", "placeholder", "readonly"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "options__empty"
}
const _hoisted_7 = {
  key: 1,
  class: "options__loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_feather = _resolveComponent("vue-feather")!
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_simple_bar = _resolveComponent("simple-bar")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "target",
    class: _normalizeClass(["position-relative select", _ctx.getSelectClass])
  }, [
    (_ctx.state.values.length && _ctx.multiple && !_ctx.disabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "select__reset btn btn-sm btn-light",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args)))
        }, [
          _createVNode(_component_vue_feather, {
            class: "d-flex",
            size: "20px",
            type: "x"
          })
        ]))
      : _createCommentVNode("", true),
    (!_ctx.state.values.length || !_ctx.multiple)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_vue_feather, {
            class: "d-flex",
            size: "20px",
            type: "chevron-down"
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.multiple && _ctx.searchable && _ctx.state.values.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Выбрано: " + _toDisplayString(_ctx.getValue), 1))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", _mergeProps({
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getModel) = $event))
      }, _ctx.$attrs, {
        autocomplete: "off",
        class: ["form-control position-relative", _ctx.getInputClass],
        disabled: _ctx.getDisabled,
        placeholder: _ctx.getPlaceholder,
        readonly: _ctx.getReadonly,
        onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.input($event)))
      }), null, 16, _hoisted_4), [
        [_vModelDynamic, _ctx.getModel]
      ])
    ]),
    (_ctx.state.isOpen)
      ? (_openBlock(), _createBlock(_component_simple_bar, {
          key: 2,
          "auto-hide": false,
          class: "card border select__options options simple-bar-dark"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOptions, (option, optionInd) => {
              return (_openBlock(), _createElementBlock("div", {
                key: option.id,
                class: _normalizeClass(["options__item", _ctx.getOptionClass(option)]),
                onClick: ($event: any) => (_ctx.select(option))
              }, [
                _renderSlot(_ctx.$slots, "option", { option: option }, () => [
                  _createTextVNode(_toDisplayString(option.name) + " (" + _toDisplayString(option.entrance_bin) + ") ", 1)
                ], true)
              ], 10, _hoisted_5))
            }), 128)),
            (!_ctx.getOptions.length && !_ctx.state.loader)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Ничего не найдено "))
              : _createCommentVNode("", true),
            (_ctx.state.loader)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_loader_ui, {
                    "is-show": _ctx.state.loader,
                    size: "sm"
                  }, null, 8, ["is-show"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true)
  ], 2))
}